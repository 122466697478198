import React from 'react';
import { connect } from 'react-redux';
import JobFilterList from './JobFilterList';
import SkillsCountsList from './SkillsCountsList';
import { Header, Segment, Item, Radio } from 'semantic-ui-react';
import { setJobsFilterAction, setJobsSkillFilterAction } from '../../../redux/filters/filters.actions';

function JobFilter({
  includedSkills,
  excludedSkills,
  jobsSkillsCounts,
  jobsFilter,
  setJobsFilterAction,
  jobsSkillFilter,
  setJobsSkillFilterAction
  }) 
  {
    
  function setFilter(f) {
    console.log('set filter', f);
    setJobsFilterAction(f);
  }

  console.log('JobFilter');
  // console.log('jobsFilter', jobsFilter);
  // console.log('jobsSkillFilter', jobsSkillFilter);
  //console.log('includedSkills', includedSkills);
  //console.log('excludedSkills', excludedSkills);
  //console.log('jobsSkillsCounts', jobsSkillsCounts);

  return (
    <div className="job-filter">
      <Segment>
      <Header size="large" className="xjob-filter-header">
        Filter Jobs by Skills
        <div>
          <div className="middle">
            <Radio className="hradio"
              label="No Filter"
              name="radioGroup"
              value="N"
              checked={jobsFilter === 'N'}
              onClick={() => setFilter('N')}
            />
            <Radio
              label="Exclude"
              name="radioGroup"
              value="E"
              checked={jobsFilter === 'E'}
              onClick={() => setFilter('E')}
            />
            <Radio
              label="Include"
              name="radioGroup"
              value="I"
              checked={jobsFilter === 'I'}
              onClick={() => setFilter('I')}
            />
            <Radio
              label="Include - Exclude"
              name="radioGroup"
              value="I-E"
              checked={jobsFilter === 'I-E'}
              onClick={() => setFilter('I-E')}
            />
          </div>

          <span className="inline">
            <Radio
              label="Skill Name"
              name="radioGroup"
              value="S"
              checked={jobsFilter === 'S'}
              onClick={() => setFilter('S')}
            />
            <input className="block"
              type="text"
              name="SkillName"
              defaultValue={jobsSkillFilter}
              readOnly
              />
          </span>
        </div>
      </Header>
      </Segment>
      <Segment.Group>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
              <Item.Header>Skill Inclusion Filter</Item.Header>
                <JobFilterList items={includedSkills} />
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
              <Item.Header>Skill Exclution Filter</Item.Header>
                <JobFilterList items={excludedSkills} />
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>Skill Counts</Item.Header>
                <SkillsCountsList items={jobsSkillsCounts} />
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment.Group>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log('MSTP JF: ', state);
  return {
    includedSkills: state.skills.includedSkills,
    excludedSkills: state.skills.excludedSkills,
    jobsSkillsCounts: state.skills.jobsSkillsCounts,
    jobsFilter: state.filters.jobsFilter,
    jobsSkillFilter: state.filters.jobsSkillFilter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setJobsFilterAction: (f) => dispatch(setJobsFilterAction(f)),
  setJobsSkillFilterAction: (f) => dispatch(setJobsSkillFilterAction(f)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobFilter);
