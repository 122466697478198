import React from 'react';
import SkillCountItem from './SkillCountItem';

const SkillsCountsList = ({ items }) => {
  console.log('SkillsCountsList');
  if (!items || items.length === 0) {
    return <h2>Loading...</h2>;
  }
  else {
    return (
      <div>
        <div>Items: {items.length}</div>
        <div>
          {items.map((item) => (
            <SkillCountItem key={item.skillName} item={item} />
          ))}
        </div>
      </div>
    );
  }
};

export default SkillsCountsList;
