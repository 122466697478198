import React from 'react';
import firebase from 'firebase/app'
import 'firebase/firestore'
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import store from './redux/store'
import firebaseConfig from './redux/firebase'
import App from './App';
import './index.css';

const rrfConfig = { userProfile: 'users' } // react-redux-firebase config

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
