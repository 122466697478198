import store from '../../redux/store';
import firebase from 'firebase/app';

export function updateJob(jobNumber, watching) {
    const db = firebase.firestore();
    console.log('updateJob', `${jobNumber} ${watching}`);
  if ( !watching )
  {
      // delete document from watchlist collection
      db.collection('watchlist').doc(jobNumber).delete();
      return;
  }
  db.collection('watchlist').doc(jobNumber).set({ watching: watching });
}

export function isWatched(jn) {
  const state = store.getState();
  let sfo = state.firestore.ordered;
  //console.log('watching', sfo.watchlist);

  let j = sfo.watchlist.find((i) => i.id === jn);
  if (j) {
    console.log('found watching', jn);
    return true;
  }
  return false;
}
