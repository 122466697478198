import React from 'react';
import { Modal, Radio, List, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../redux/modal/modals.actions';
// import { updateSkillStartAction } from '../redux/skills/skills.actions';
// import { fetchJobsStartAction } from '../redux/jobs/jobs.actions';
import { updateSkillsDeep } from '../components/skills/SkillUtilities';

//const UpdateSkillModal = ({ closeModal, data }) => {
export class UpdateSkillModal extends React.Component {
  state = {
    id: this.props.data.name,
    value: this.props.data.ie,
    initialValue: this.props.data.ie
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  updateSkill(){
    console.log('updateSkill', this.state);
    updateSkillsDeep(this.props.data.name, this.state.value);
    //this.props.updateSkillStartAction(this.props.data.id, this.state.value);
    //this.props.fetchJobsStartAction();
    this.props.closeModal();
  }

  render() {
    //console.log('UpdateSkillModal', this.props.data);
    //console.log('UpdateSkillModal state', this.state);
    return (
      <Modal closeIcon="close" open={true} onClose={this.props.closeModal} className="test-width">
        <Modal.Header>
          {this.props.data.name}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description className="desc-size">
              <List horizontal className="middle">
                <List.Item>
                  <Radio
                    label="Neutral"
                    name="modalRadioGroup"
                    value="N"
                    checked={this.state.value === 'N' }
                    onClick={this.handleChange}
                  />
                </List.Item>
                <List.Item>
                  <Radio
                    label="Exclude"
                    name="modalRadioGroup"
                    value="E"
                    checked={this.state.value === 'E'}
                    onClick={this.handleChange}
                  />
                </List.Item>
                <List.Item>
                  <Radio
                    label="Include"
                    name="modalRadioGroup"
                    value="I"
                    checked={this.state.value === 'I'}
                    onClick={this.handleChange}
                  />
                </List.Item>
              </List>
              <List horizontal className="flt-rt">
                  <Button 
                    onClick={() => this.updateSkill()}
                    disabled={this.state.value === this.state.initialValue} 
                    content="Update" 
                    />
                  <Button content="Cancel" onClick={this.props.closeModal} />
              </List>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  //console.log'MSTP SD: ', state);
  return {
    skills: state.firestore.ordered.skills
  };
};

const mapDispatchToProps = (dispatch) => ({
  // fetchJobsStartAction: () => dispatch(fetchJobsStartAction()),
  // updateSkillStartAction: (a,b) => dispatch(updateSkillStartAction(a,b)),
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSkillModal);
