import store from '../../redux/store';
import firebase from 'firebase/app';

export function updateSkillsDeep(skillName, includedExcluded) {
  const state = store.getState();
  state.skills.updated = false;

  console.log('updateSkillsDeep', skillName);
  // update a specific skill
  const db = firebase.firestore();
  db.collection('skills')
    .doc(skillName)
    .update({ includedExcluded: includedExcluded });

  updateJobsSkillsCounts(skillName, includedExcluded);
}

export function updateIESkills(name, ie) {
  const state = store.getState();
  const is = state.skills.includedSkills;
  const es = state.skills.excludedSkills;

  let newIs = [];
  let newEs = [];
  if (ie === 'E') {
    // remove from included, if there
    newIs = is.filter((f) => f.skillName !== name);
    // add to excluded
    es.push({ id: name, includedExcluded: ie, skillName: name });
    newEs = es;
  } else if (ie === 'I') {
    // remove from excluded, if there
    newEs = es.filter((f) => f.skillName !== name);
    // add to included
    is.push({ id: name, includedExcluded: ie, skillName: name });
    newIs = is;
  } else {
    newEs = es.filter((f) => f.skillName !== name);
    newIs = is.filter((f) => f.skillName !== name);
  }
  newIs.sort(function (a, b) {
    if (a.skillName < b.skillName) return -1;
    if (a.skillName > b.skillName) return 1;
    return 0;
  });
  newEs.sort(function (a, b) {
    if (a.skillName < b.skillName) return -1;
    if (a.skillName > b.skillName) return 1;
    return 0;
  });
  //console.log('e', newEs);
  //console.log('i', newIs);

  state.skills.includedSkills = newIs;
  state.skills.excludedSkills = newEs;
}

export function setIESkills() {
  const state = store.getState();
  const skills = state.firestore.ordered.skills;
  //console.log('setIESkills', state);
  if (!skills) return;
  //console.log('setIESkills', skills);
  // update the includedSkills list
  let li = [];
  let le = [];
  const count = skills.length;
  let i = 0;
  for (; i < count; ++i) {
    if (skills[i].includedExcluded === 'I') li.push(skills[i]);
    else if (skills[i].includedExcluded === 'E') le.push(skills[i]);
  }

  //console.log('e', le);
  //console.log('i', li);
  state.skills.includedSkills = li;
  state.skills.excludedSkills = le;
  // put(setIncludedSkillsAction(li));
  // put(setExcludedSkillsAction(le));
  //state.skills.updateToggle = !state.skills.updateToggle;
}

export function getJobSkills(jobId) {
  //console.log('store: ',store);
  let ujs = [];
  const state = store.getState();
  //console.log('state: ',state);
  const js = state.firestore.ordered.jobsskills;
  let count = js.length;
  let i = 0;
  for (; i < count; ++i) {
    if (js[i].jobNumber === jobId) {
      let ie = getIE(js[i].skillName);
      let jsc = Object.assign({ includedExcluded: ie }, js[i]);
      ujs.push(jsc);
    }
  }
  //console.log('ujs: ',ujs);
  return ujs;
}

export function getIE(skillName) {
  const state = store.getState();
  const si = state.skills.includedSkills;
  const se = state.skills.excludedSkills;

  if (si.some((sk) => sk.skillName === skillName)) return 'I';
  else if (se.some((sk) => sk.skillName === skillName)) return 'E';
  else return 'N';
}

export function updateJobsSkillsCounts(name, ie) {
  const state = store.getState();
  let i = 0;
  while (i < state.skills.jobsSkillsCounts.length) {
    if (state.skills.jobsSkillsCounts[i].skillName === name) {
      console.log('set count ie: ', ie);
      state.skills.jobsSkillsCounts[i].ie = ie;
      console.log('count updated: ', `${name} ${ie} ${i}`);
}
    ++i;
  }
}

export function setJobsSkillsCounts() {
  let jsc = [];
  const state = store.getState();
  const s = state.firestore.ordered.jobsskills;
  //console.log('g: ', s);
  if (!s) return;
  const gb = groupBy(s, 'skillName');
  //console.log('gb: ',gb);
  let gc = gb.sort((a, b) => b.length - a.length);
  //console.log('gc: ',gc);

  let i = 0;
  while (i < gc.length) {
    let sn = gc[i][0]['skillName'];
    let ct = gc[i].length;
    let ie = getIE(sn);
    jsc.push({ skillName: sn, skillCount: ct, ie: ie });
    ++i;
  }

  //console.log('jsc: ', jsc);
  state.skills.jobsSkillsCounts = jsc;
  //setSkillsCountsAction(jsc);
}

const groupBy = (objectArray, ...properties) => {
  return [
    ...Object.values(
      objectArray.reduce((accumulator, object) => {
        const key = JSON.stringify(properties.map((x) => object[x] || null));

        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        accumulator[key].push(object);
        return accumulator;
      }, {})
    ),
  ];
};

export default updateSkillsDeep;
