const INITIAL_STATE = {
  jobsFilter: 'N',
  jobsSkillFilter: '',
  errorMessage: undefined,
};

export const filtersReducer = function (state = INITIAL_STATE, action) {
  //console.log('JR: ', action.type);
  switch (action.type) {
    case 'JOBS_SET_FILTER':
      console.log('JSF: ', action.payload);
      return {
        ...state,
        jobsFilter: action.payload,
      };
    case 'JOBS_SET_SKILL_FILTER':
      console.log('JSSF: ', action.payload);
      return {
        ...state,
        jobsSkillFilter: action.payload.name,
        jobsFilter: action.payload.filter,
      };
    default:
      return state;
  }
};
