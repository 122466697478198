import React, { Component } from 'react';
//import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { updateSkillsDeep } from '../components/skills/SkillUtilities';
import './popup.css';

class SkillsPopup extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    }; // state to control the state of popup
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  updateSkill = (e) => {
    console.log('clicked', e.target);
    console.log('updateSkill', this.state);
    const action = e.target.innerText;
    let value = '';
    switch (action) {
      case 'Neutral':
        value = 'N';
        break;
      case 'Exclude':
        value = 'E';
        break;
      case 'Include':
        value = 'I';
        break;
      case 'Filter by':
        value = 'N';
        break;
      default:
        this.handleClose();
        break;
    }
    updateSkillsDeep(this.props.name, value);
    this.handleClose();
  }

  render() {
    return (
      <div>
        <Popup
          open={this.state.isOpen}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          style={{ padding: '0px' }}
          position="bottom center"
          trigger={this.props.trigger}
          on={['click']}
          onClick={() => this.clicked}
          content={
            <div className={'popup'}>
              <button
                className={'popup-item'}
                disabled={this.props.ie === 'N'}
                onClick={this.updateSkill}
              >
                Neutral
              </button>
              <button
                className={'popup-item'}
                disabled={this.props.ie === 'E'}
                onClick={this.updateSkill}
              >
                Exclude
              </button>
              <button
                className={'popup-item'}
                disabled={this.props.ie === 'I'}
                onClick={this.updateSkill}
              >
                Include
              </button>
            </div>
          }
        />
      </div>
    );
  }
}

// const mapStateToProps = state => {
//     return {
//       skills: state.firestore.ordered.skills
//     };
//   };
  
// export default connect(mapStateToProps, null)(SkillPopup);
export default SkillsPopup;