import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Container } from 'semantic-ui-react';

const NavBar = () => {
  return (
      <Menu>
        <Container>
          <Menu.Item header>
            Toptal Job Search
          </Menu.Item>
          <Menu.Item as={Link} to='/' header>
            Jobs
          </Menu.Item>
          <Menu.Item as={Link} to='/skills'>
            Skills
          </Menu.Item> 
        </Container>
      </Menu>
    );
  }

export default NavBar;
