import React from 'react';
import JobFilterItem from './JobFilterItem';

const JobFilterList = ({items}) => {
    if (!items)
      return (<h2>Loading...</h2>)
    else {
      console.log('JobFilterList');
      return (
      <div>
        <div>Items: {items.length}</div>
        <div>
          {items.map(item => (
            <JobFilterItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    );
  }
}

export default JobFilterList;
