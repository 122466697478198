import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Segment } from 'semantic-ui-react';
import Select from 'react-select';
import { Checkbox } from '../../ui/ui';
import JobListItem from './JobListItem';
import { getJobSkills } from '../../skills/SkillUtilities';

function filterJobs(jobs, watchlist, filter, skillName, tz, wf, usc) {
  let j = [];
  const count = jobs.length;
  let i = 0;
  for (; i < count; ++i) {
    let jc = Object.assign({}, jobs[i]);
    const s = getJobSkills(jc.jobNumber);
    jc.skills = s;

    // filter for watching (overrides other filters)
    if (wf) {
      if (!watchlist.some(w => w.id === jc.jobNumber)) continue;
      j.push(jc);
      continue; // skip other filters
    }

    // filter for US & Canada
    if (usc) {
      let ok = false;
      if (jc.location.includes('United States')) ok = true;
      if (jc.location.includes('Canada')) ok = true;
      if (!ok) continue;
    }

    // filter for time zone
    if (tz) {
      //console.log(`${tz} ${jc.timeZone}`);
      if (jc.UTCOffset !== tz) continue;
    }

    // filter for job skills
    if (testSkills(s, filter, skillName)) {
      j.push(jc);
    }
  }
  j.sort(function (a, b) {
    if (a.posted > b.posted) return -1;
    if (a.posted < b.posted) return 1;
    return 0;
  });

  return j;
}

function testSkills(skills, filter, skillName) {
  let hasSome = false;
  if (filter === 'E') {
    hasSome = skills.some((s) => s.includedExcluded === filter);
    return !hasSome;
  }
  if (filter === 'I') {
    hasSome = skills.some((s) => s.includedExcluded === filter);
    return hasSome;
  }
  if (filter === 'I-E') {
    let hasI = skills.some((s) => s.includedExcluded === 'I');
    let hasE = skills.some((s) => s.includedExcluded === 'E');
    hasSome = hasI && !hasE;
    return hasSome;
  }
  if (filter === 'S') {
    hasSome = skills.some((s) => s.skillName === skillName);
    return hasSome;
  }

  return true;
}

function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

function JobList(props) {
  const jobs = props.jobs;
  const watchlist = props.watchlist;
  let jobsFilter = props.jobsFilter;
  let jobsSkillFilter = props.jobsSkillFilter;

  useTraceUpdate(props);
  const [timeZone, setTimeZone] = useState('');
  const [watchingFilter, setWatchingFilter] = useState(false);
  const [uscFilter, setUSCFilter] = useState(false);

  const setTimeFilter = (e) => {
    console.log(e);
    setTimeZone(e ? e.value : '');
  };

  const toggleWatching = () => {
    console.log('toggleWatching', watchingFilter);
    setWatchingFilter(!watchingFilter);
  };

  const toggleUSC = () => {
    console.log('toggleUSC', uscFilter);
    setUSCFilter(!uscFilter);
  };

  let filteredJobs = filterJobs(
    jobs,
    watchlist,
    jobsFilter,
    jobsSkillFilter,
    timeZone,
    watchingFilter,
    uscFilter
  );
  if (jobsFilter !== 'S') jobsSkillFilter = '';
  //console.log('JL filtered jobs', filteredJobs);

  // collect list of UTC Offsets
  let tz = [];
  jobs.map((item, index) =>
    tz.push({ key: index, label: item.UTCOffset, value: item.UTCOffset })
  );
  let tzu = tz.filter(
    (e, i) => tz.findIndex((a) => a['label'] === e['label']) === i
  );
  tzu.unshift({ key: 999, label: 'None', value: '' });

  console.log('watchlist', watchlist);

  console.log('JobList');
  return (
    <div>
      <Segment>
        <div>
          <h4
            style={{
              width: '200px',
              borderTop: '20px',
              display: 'inline-block',
            }}
          >
            Jobs: {filteredJobs.length}&nbsp;|&nbsp;Filter: {jobsFilter}&nbsp;
            {jobsSkillFilter}
          </h4>
        </div>
        <div>
          <div
            style={{ width: '150px', padding: '0px', display: 'inline-block' }}
          >
            <Select onChange={setTimeFilter} options={tzu} isClearable={true} />
          </div>
          <div
            style={{
              minWidth: '50px',
              paddingLeft: '20px',
              display: 'inline-block',
            }}
          >
            <Checkbox
              label="WATCHING"
              isChecked={watchingFilter}
              onChange={toggleWatching}
            />
          </div>
          <div
            style={{
              minWidth: '50px',
              paddingLeft: '20px',
              display: 'inline-block',
            }}
          >
            <Checkbox
              label="US / Canada"
              isChecked={uscFilter}
              onChange={toggleUSC}
            />
          </div>
        </div>
      </Segment>
      <Segment style={{ overflow: 'auto', maxHeight: 1000 }}>
        <div>
          {filteredJobs ? (
            filteredJobs.map((item, index) => (
              <JobListItem key={item.id} job={item} index={index} />
            ))
          ) : (
            <h4>No Jobs!</h4>
          )}
        </div>
      </Segment>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log('MSTP JL: ', state);
  return {
    jobs: state.firestore.ordered.jobs,
    watchlist: state.firestore.ordered.watchlist,
    jobsFilter: state.filters.jobsFilter,
    jobsSkillFilter: state.filters.jobsSkillFilter,
    watchingFilter: state.filters.watchingFilter,
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'watchlist' },
      { collection: 'jobs' },
    ];
  })
)(JobList);
