import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import SkillList from './SkillList';

const SkillDashboard = ({ skills }) => {
  //const [searchField, setSearchField] = useState('');
  //const [filter, setFilter] = useState('');

  console.log('SkillDashboard');
  return (
    <Container className="skills-container">
      <SkillList skills={skills} />
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    skills: state.firestore.ordered.skills
      ? state.firestore.ordered.skills.map((c) => c)
      : [],
  };
};

export default connect(mapStateToProps, null)(SkillDashboard);
