import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import logger from 'redux-logger';

const initialState = {
}

const middleware = [logger];

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware) // to add other middleware
  )

export default store;

