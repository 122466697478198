//import store from '../store';

const INITIAL_STATE = {
  includedSkills: [],
  excludedSkills: [],
  jobsSkillsCounts: [],
  errorMessage: undefined,
};

export const skillsReducer = function (state = INITIAL_STATE, action) {
  if (action.type[0] !== '@') console.log('SR: ', action);
  switch (action.type) {
    case 'SET_INCLUDED_SKILLS':
      return {
        ...state,
        includedSkills: action.payload,
      };
    case 'SET_EXCLUDED_SKILLS':
      return {
        ...state,
        excludedSkills: action.payload,
      };
    case 'SET_SKILLS_COUNTS':
      return {
        ...state,
        jobsSkillsCounts: action.payload,
      };
    default:
      return state;
  }
};
