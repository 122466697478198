import React from 'react';
import './ui.css';


export const Checkbox = ({ label, isChecked, onChange }) => (
    <div className="ui-check">
      <label className="ui-check-label">
        <input className="ui-check-input"
          type="checkbox"
          name={label}
          checked={isChecked}
          onChange={onChange}
        />
        <span className="ui-check-span">{label}</span>
      </label>
    </div>
  );