
const firebaseConfig = {
    apiKey: "AIzaSyBUoRnNFJfG-V6pZnB97Zb2zhoTzLW3vjc",
    authDomain: "tjobs-6a549.firebaseapp.com",
    databaseURL: "https://tjobs-6a549.firebaseio.com",
    projectId: "tjobs-6a549",
    storageBucket: "tjobs-6a549.appspot.com",
    messagingSenderId: "835468511179",
    appId: "1:835468511179:web:f7207a199c107b8220d84e",
    measurementId: "G-DFHK82NFE8"
  };

// const  firebaseConfig = {
//     apiKey: "AIzaSyDP3opAFWVoSjkwidDpJuQ0yRdIhlEAvJg",
//     authDomain: "tjobsfb.firebaseapp.com",
//     databaseURL: "https://tjobsfb.firebaseio.com",
//     projectId: "tjobsfb",
//     storageBucket: "tjobsfb.appspot.com",
//     messagingSenderId: "980123083284",
//     appId: "1:980123083284:web:7e2b2e25be00c16a49bd01",
//     measurementId: "G-9KB7R426RK"
//   };

  export default firebaseConfig;