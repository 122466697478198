import React, { useState } from 'react';
import { List, Icon, Confirm } from 'semantic-ui-react';
import { updateSkillsDeep } from '../../skills/SkillUtilities';

const JobFilterItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  function show() { setOpen(true) };
  function handleConfirm() {
    console.log('handleConfirm', item.id)
    updateSkillsDeep(item.id, 'N');
    setOpen(false)
  };
  function handleCancel() {setOpen(false)};

  const c = item.includedExcluded === 'I' ? 'included-skill' : 'excluded-skill';
  return (
    <div>
      <List.Item className="job-filter-item">
        <div className={c}>
          {item.skillName}
          <Icon className="flt-rt" link name="trash alternate outline" onClick={show} />
        </div>
      </List.Item>
      <Confirm
        className="remove-skill"
        content="Are you SURE you want to remove this skill from the list?"
        open={open}
        cancelButton="Never mind"
        confirmButton="Let's do it"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default JobFilterItem;
