import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Container, Segment, Loader, Dimmer } from 'semantic-ui-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import { firestoreConnect } from 'react-redux-firebase';
import {
  setIESkills,
  setJobsSkillsCounts,
  updateIESkills,
} from './components/skills/SkillUtilities';
import ModalManager from './modals/ModalManager';
import NavBar from './components/NavBar';
import JobDashboard from './components/jobs/JobDashboard/JobDashboard';
import SkillDashboard from './components/skills/SkillDashboard';

class App extends React.Component {
  shouldUpdate = true;
  observer;

  componentDidMount() {
    const db = firebase.firestore();

    //let query = db.collection('skills').where('skillName', '==', 'Android');
    let query = db.collection('skills');
    this.observer = query.onSnapshot((snap) => {
      console.log('Skill snap', snap);
      let changes = snap.docChanges();
      if (changes.length === 0) return;

      //console.log('changes', changes);
      if (changes[0].type !== 'modified') return;

      //console.log('doc', snap.changes[0].doc[0]);
      changes.forEach((ch) => {
        //console.log('change', ch);
        //console.log('change doc', ch.doc);
        const data = ch.doc.data();
        //console.log('change doc data', data);
        updateIESkills(data.skillName, data.includedExcluded);
      });

      //setIESkills();
    });
  }

  componentWillUnmount() {
    this.observer();
    console.log('App unmount');
  }

  shouldComponentUpdate(nextProps) {
    // console.log("shouldUpdate", nextProps);
    // if (this.props.jobs !== nextProps.jobs) return true;
    // if (this.props.skills !== nextProps.skills) return true;
    // if (this.props.jobsskills !== nextProps.jobsskills) return true;
    // return false;
    return this.shouldUpdate;
  }

  isLoading() {
    const req = this.props.fs.requesting;
    if (Object.keys(req).length === 0) return true;
    console.log(`${req.jobs} ${req.skills} ${req.jobsskills} ${req.watchlist}`);
    if (req.jobs || req.skills || req.jobsskills || req.watchlist) return true;
    return false;
  }

  render() {
    //const req = this.props.fs.requesting;
    //console.log(Object.keys(req).length);

    if (this.isLoading()) {
      console.log('isLoading');
      return (
        <Segment style={{ width: '100%', height: '100%' }}>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      );
    }
    this.shouldUpdate = false;
    setIESkills();
    setJobsSkillsCounts();
    console.log('App');
    return (
      <BrowserRouter>
        <div>
          <NavBar />
          <Container className="main">
            <ModalManager />
            <Route exact path="/" component={JobDashboard} />
            <Route path="/skills" component={SkillDashboard} />
          </Container>
        </div>
      </BrowserRouter>
    );
  }
}

// export default compose(
//   firestoreConnect(() => ['watchlist','jobs', 'skills', 'jobsskills']),
//   connect((state) => ({
//     fs: state.firestore,
//   }))
// )(App);

const mapStateToProps = (state) => {
  //console.log('MSTP JL: ', state);
  return {
    jobs: state.firestore.ordered.jobs,
    skills: state.firestore.ordered.skills,
    jobsskills: state.firestore.ordered.jobsskills,
    watchlist: state.firestore.ordered.watchlist,
    fs: state.firestore.status,
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'watchlist' },
      { collection: 'jobs' },
      { collection: 'jobsskills' },
      { collection: 'skills' },
    ];
  })
)(App);
