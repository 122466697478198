import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import JobList from '../JobList/JobList';
import JobFilter from '../JobFilter/JobFilter';

const JobDashboard = () => {
  console.log('JobDashboard');
  return (
    <div>
      <div className="job-dashboard">
        <Header size="huge">Jobs Dashboard</Header>
      </div>
      <Grid className="job-dashboard-grid">
        <Grid.Column width={10}>
          <JobList />
        </Grid.Column>
        <Grid.Column width={6}>
          <JobFilter />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default JobDashboard;
