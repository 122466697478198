import React from 'react';
import { List } from 'semantic-ui-react';
import SkillListItem from './SkillListItem';

function SkillList({ skills }) {
  return (
    <div className="skills-list">
      <List horizontal>
        {skills.map((s) => {
          return <SkillListItem key={s.id} skill={s} />;
        })}
      </List>
    </div>
  );
}

export default SkillList;
