import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { List } from 'semantic-ui-react';
import SkillPopup from '../../../popups/skillPopup';
import { getIE } from '../../skills/SkillUtilities';

class JobListSkill extends Component {
  skills = this.props.skills;
  name = this.props.name;
  ie = this.props.ie;
  popup = false;
  observer;

  shouldComponentUpdate(nextProps)
  {
    //const skill = this.skills.find(s => s.skillName === this.name);
    const newIE = getIE(this.name);
    //console.log('should', `${this.name} ${this.ie} ${newIE}`);
    if  (this.ie !== newIE)
    {
      this.ie = newIE;
      //console.log('update');
      return true;
    }
    return false;
  }
  
  clicked = (e) => {
    console.log('JLS clicked', e.target);
    this.popup = true;
  }

  render() {
    let c = "skill-n";
    if  (this.ie === 'I') { c = "skill-in" }
    else if  (this.ie === 'E') { c = "skill-ex" }
    //console.log("JobListSkill", `${this.name} ${this.ie}`);
    return (
      <List.Item
        as='a'
        className={`job-skill-item ${c}`}>
        <SkillPopup 
          trigger={
            <div>{this.name} &nbsp;  <i className="angle right icon" /></div>
          } 
          name={this.name} 
          ie={this.ie} />
      </List.Item>
    );
  }
}

const mapStateToProps = state => {
  return {
    skills: state.firestore.ordered.skills
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      { 
        collection: 'skills' 
      },
    ];
  })
)(JobListSkill);
//export default connect(mapStateToProps, null)(JobListSkill);
//export default JobListSkill;
