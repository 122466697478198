export const modalsReducer = function(state = null, action) {
    //console.log('MR: ', action.type);
    switch (action.type) {
      case "MODAL_OPEN":
        //console.log('MO: ', action);
        const {modalType, modalProps} = action.payload;
        return {modalType, modalProps};
      case "MODAL_CLOSE":
        //console.log('MC: ', state);
        return null;
      default:
        return state;
    }
  };
  