
export const openModal = (modalType, modalProps) => {
    //console.log('openModal', modalType);
    //console.log('props', modalProps);
    return {
        type: "MODAL_OPEN",
        payload: {
            modalType,
            modalProps
        }
    }
}

export const closeModal = () => {
    return {
        type: "MODAL_CLOSE"
    }
}