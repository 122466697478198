import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import { filtersReducer } from './filters/filters.reducer'
import { skillsReducer } from './skills/skills.reducer'
import { modalsReducer } from './modal/modals.reducer'

// Add firebase to reducers
export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  filters: filtersReducer,
  skills: skillsReducer,
  modals: modalsReducer,
})
