import React from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';
import SkillsPopup from '../../popups/skillsPopup';

//import { openModal } from '../../modals/modalActions';

const actions = {
  //openModal
};

const SkillListItem = ({ skill, openModal }) => {
  let c = 'skill-n';
  if (skill.includedExcluded === 'I') c = 'skill-in';
  else if (skill.includedExcluded === 'E') c = 'skill-ex';

  return (
    <List.Item
    as="a" 
    className={`skill-list-item ${c}`} >
    <SkillsPopup 
      trigger={<div>{skill.skillName}</div>} 
      name={skill.skillName}
      ie={skill.includedExcluded} 
      />
    </List.Item>
  );
};

//export default SkillListItem;
export default connect(null, actions)(SkillListItem);
