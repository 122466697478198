import React from 'react';
import { Segment, Item, List, Grid } from 'semantic-ui-react';
import JobListSkill from './JobListSkill';
import SVG from '../../../svg/svg';
import moment from 'moment';
import { Checkbox } from '../../ui/ui';
import { updateJob, isWatched } from '../jobUtilities';

const JobListItem = ({ job, index }) => {
  const js = job.skills; // these are the skills required for this job
  const watched = isWatched(job.jobNumber);
  //console.log('JobListItem', job);

  let cols = [
    { i: 'clockPeriod',l:'Commitment',t: job.requiredAvailability },
    { i: 'language',l:'Client Languages', t: job.languages },
    { i: 'jobtype',l:'Job Type',t: job.jobType },
    { i: 'timespan',l:'Est. Length',t: job.estimatedLength },
    { i: 'clock',l:'Time Zone',t: job.timeZone },
    { i: 'calendar',l:'Job Posted',t: moment(job.posted).fromNow() },
    { i: 'clock',l:'UTC Offset',t: job.UTCOffset },
    { i: 'overlap',l:'Min. Overlap',t: job.minimumOverlap },
    { i: 'calendar',l:'Desired Start Date',t: job.startDate },
    { i: 'location',l:'City/Country', t: job.location },
    { i: "team",l:'Team Size', t:job.teamSize},
    { i: 'founded',l:'Year Founded', t: job.founded },
    { i: 'briefcase',l:'Industry', t: job.industry },
    { i: "team",l:'Hired', t: `${job.hiredCount} of ${job.positionsCount}`},
    { i: 'calendar',l:'Posted Raw', t: job.posted },
    { i: 'briefcase',l:'Job Number', t: job.jobNumber },
    //{ i: 'calendar',l:'', t: job.timeEntered.toDate().toString() },
  ];

  const renderItem = (col, ix) => {
      return (
          <Grid.Column key={ix} style={{paddingTop: '5px', paddingBottom: '0px'}}>
            <SVG svg={col.i} label={col.l} text={col.t} />
          </Grid.Column>
          ); 
    };

  const updateWatch = (w) => {
      console.log('updateWatch', w);
      updateJob(job.jobNumber, w ? false : true);
    }
    
      return (
    <Segment.Group>
      <Segment className="job-item-title"
      >
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header 
                as="a"
                href={`https://talent.toptal.com/portal/job/${job.jobNumber}`}
                target="_blank"
                className="job-item-header">
                <div>
                  {job.title}&nbsp;
                  <div className="flt-rt job-status">
                    <p>{job.status} ({index})</p>
                    <Checkbox 
                      label='WATCHING'
                      isChecked={watched}
                      onChange={() => updateWatch(watched)}
                      />
                  </div>
                </div>
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <Grid columns="2" style={{margin: '0',paddingBottom: '20px'}}>
          {cols.map((col, index) => renderItem(col, index))}
        </Grid>
      </Segment>
      <Segment secondary>
        <List horizontal>
          {js.map((si) => (
            <JobListSkill key={si.skillName} name={si.skillName} ie={si.includedExcluded} />
          ))}{' '}
        </List>
      </Segment>
      <Segment className="job-desc">
        <div>{job.description}</div>
      </Segment>
    </Segment.Group>
  );
};

export default JobListItem;
