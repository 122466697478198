import React from 'react';
import { List } from 'semantic-ui-react';
import SkillPopup from '../../../popups/skillPopup';

const SkillCountItem = ({ item }) => {
  //console.log("SkillCountItem: ", item);

  let c = 'skill-n';
  if (item.ie === 'I') {
    c = 'skill-in';
  } else if (item.ie === 'E') {
    c = 'skill-ex';
  }

  return (
    <List.Item className={'job-filter-item'}>
      <SkillPopup
        trigger={
          <div className={`skill-counts ${c}`}>
            {item.skillName}
            <p className="flt-rt">{item.skillCount}</p>
          </div>
        }
        name={item.skillName}
        ie={item.ie}
      />
    </List.Item>
  );
};

export default SkillCountItem;
