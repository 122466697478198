import React from 'react';
import './svg.css';

const SVG = ({
  style = {},
  fill = '#000',
  width = '25',
  className = '',
  height = '16',
  viewBox = '0 0 16 16',
  text = 'placeholder',
  label = 'label',
  svg = '',
}) => {
  let path = '';
  switch (svg) {
    case 'briefcase':
      path =
        'M10 5H1v4h14V5h-5zM6 4V2h4v2h6v11H0V4h6zm1 0h2V3H7v1zm8 6H1v4h14v-4z';
      break;
    case 'calendar':
      path =
        'M3 2V1h1v1h8V1h1v1h3v13H0V2h3zm0 1H1v2h14V3h-2v1h-1V3H4v1H3V3zm12 3H1v8h14V6z';
      break;
    case 'clockPeriod':
      path =
        'M7.5 0a7.5 7.5 0 017.484 8H13.98a6.5 6.5 0 10-5.98 5.98v1.004A7.5 7.5 0 117.5 0zm4.12 9.615c.935 0 1.335.88 1.335 1.72s-.4 1.725-1.335 1.725c-.935 0-1.335-.885-1.335-1.725 0-.84.4-1.72 1.335-1.72zm-3.08 0c.645 0 1.21.365 1.21 1.03 0 .533-.424 1.017-1.12 1.576l-.34.264h1.48V13H7.355v-.46c1.295-.98 1.8-1.415 1.8-1.895 0-.35-.3-.51-.605-.51-.39 0-.69.16-.9.4l-.34-.385c.295-.355.765-.535 1.23-.535zm5.485.05v1.25c.16-.19.475-.39.855-.39.52 0 .78.27.78.765V13h-.525v-1.52c0-.375-.195-.49-.49-.49a.811.811 0 00-.62.325V13H13.5V9.665h.525zm-2.405.47c-.545 0-.74.565-.74 1.2s.195 1.205.74 1.205c.545 0 .74-.57.74-1.205 0-.635-.195-1.2-.74-1.2zM8 2v6H4V7h3V2h1z';
      break;
    case 'clock':
      path =
        'M7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zm0-1a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM7 8H4V7h3V2h1v6H7z';
      break;
    case 'founded':
      path =
        'M6 7v1H1v3h5v1H0V3h4V1h4v2h4v2h-1V4H1v3h5zM5 3h2V2H5v1zm6.5 7.793l2-2 .707.707-2 2-.707.707L9.793 10.5l.707-.707 1 1zm0 4.207a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-1a3.5 3.5 0 100-7 3.5 3.5 0 000 7z';
      break;
    case 'jobtype':
      path =
        'M4 6v1H1v3h3v1H0V2h4V0h4v2h4v3h-1V3H1v3h3zm1-4h2V1H5v1zm2 13h7v1H7v-1zM5 6h11v8H5V6zm1 1v6h9V7H6z';
      break;
    case 'language':
      path =
        'M6 12l-4 4v-4H0V0h16v12H6zm-3 1.586L5.586 11H15V1H1v10h2v2.586zM11.039 9H9.842l-.441-1.152H6.647L6.206 9H5.009l2.358-6.003h1.314L11.039 9zM9.104 6.921l-1.08-2.862-1.08 2.862h2.16z';
      break;
    case 'location':
      path =
        'M8 16C4 11.542 2 8.21 2 6a6 6 0 1112 0c0 2.21-2 5.542-6 10zM3 6c0 1.754 1.657 4.633 5 8.489 3.343-3.856 5-6.735 5-8.489A5 5 0 003 6zm5 2a2 2 0 110-4 2 2 0 010 4zM7 6a1 1 0 102 0 1 1 0 00-2 0z';
      break;
    case 'overlap':
      path =
        'M12 2H2v10H1V1h11v1zm2 2h1v11H4v-1h10V4zM4 4h8v8H4V4zm1 1v6h6V5H5z';
      break;
    case 'team':
      path =
        'M2.691 10L5 5.382V0h6v5.38L13.31 10H16v6h-6v-3H6v3H0v-6h2.691zm1.118 0H6v2h4v-2h2.191l-2-4H5.81l-2 4zM5 13v-2H1v4h4v-2zm6-1v3h4v-4h-4v1zM6 5h4V1H6v4z';
      break;
    case 'timespan':
      path =
        'M1 1v14H0V1h1zm15 0v14h-1V1h1zm-5.5 3.29l3 3 .707.707-3.707 3.707-.707-.707 2.999-3-3-3 .708-.707zm-5 0l.707.707-3 3 3 3-.707.707-3.707-3.707.707-.707 3-3z';
      break;
    default:
      path = '';
      break;
  }

  return (
    <div className="svg-div">
      <span className="svg-div-span">
        <svg
          width={width}
          style={style}
          height={height}
          viewBox={viewBox}
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path d={path} fill={fill} />
        </svg>
      </span>
      <div className="svg-div-div">
        <div className="svg-div-label">
          &nbsp;{label}: &nbsp;
        </div>
        <div className="svg-div-text">
          {text}
        </div>
      </div>
    </div>
  );
};

export default SVG;
